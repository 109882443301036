import Link from "next/link";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { parseCookies, setCookie } from "nookies";

const ConsentContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: #f9f9f9;
  z-index: 9999;
`;

const ConsentText = styled.p`
  margin: 0;
  font-size: 14px;
  color: black;

  a {
    color: #0070f3;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const AcceptButton = styled.button`
  margin-left: 10px;
  background-color: #0070f3;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background-color: #0061cc;
  }
`;

const PrivacyConsent = () => {
  const [accepted, setAccepted] = useState(true);

  useEffect(() => {
    // Check if cookie has already been set
    const cookies = parseCookies();
    if (cookies.privacyConsent === "true") {
      setAccepted(true);
    } else {
      setAccepted(false);
    }
  }, []);

  const handleAccept = () => {
    // Set cookie to store user's consent
    setCookie(null, "privacyConsent", "true", {
      maxAge: 30 * 24 * 60 * 60, // 30 days
      path: "/",
    });
    setAccepted(true);
  };

  if (accepted) {
    return null;
  }

  return (
    <ConsentContainer>
      <ConsentText>
        We use cookies to improve your experience on our site. By clicking
        "Accept", you consent to the use of cookies.{" "}
        <Link href="/privacy" target="_self">
          Privacy Policy
        </Link>
        .
      </ConsentText>
      <AcceptButton onClick={handleAccept}>Accept</AcceptButton>
    </ConsentContainer>
  );
};

export default PrivacyConsent;
