import { setCookie, destroyCookie } from "nookies";

const authTokenKey = "prohub";

export const setMemberCookie = (payload, req?) =>
  setCookie(req, authTokenKey, JSON.stringify(payload), {
    path: "/",
  });

export const resetMemberCookie = (req = null) => {
  destroyCookie(req, authTokenKey, {
    path: "/",
  });
};

export const getMemberCookie = (req?) => {
  if (req) {
    const { cookie } = req.headers;
    if (!cookie) {
      return null;
    }
    const userCookie = cookie
      .split(";")
      .find((c) => c.trim().startsWith(`${authTokenKey}=`));
    if (!userCookie) {
      return null;
    }
    const user = decodeURIComponent(userCookie.split(`${authTokenKey}=`)[1]);
    return JSON.parse(user);
  } else {
    if (typeof window !== "undefined") {
      const cookieData = require("component-cookie")(authTokenKey);
      return cookieData === undefined ? "" : JSON.parse(cookieData);
    } else {
      return "";
    }
  }
};
