import { createContext, useContext, useState, useEffect } from "react";
import { ReactFacebookLoginInfo } from "react-facebook-login";
import axios from "axios";
import { API_BASE_URL, APP_SECRET_KEY } from "../config/constants/endpoints";
import {
  getMemberCookie,
  resetMemberCookie,
  setMemberCookie,
} from "../utils/cookie";
import { useRouter } from "next/router";
interface AuthContextData {
  user: User | null;
  isLoggedIn: boolean;
  login: (email: string, password: string) => Promise<void>;
  register: (
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ) => Promise<void>;
  getMe: () => Promise<User | null>;
  refreshToken: () => Promise<void>;
  verifyEmail: (token: string) => Promise<void>;
  resendEmailVerify: () => Promise<void>;
  logout: () => void;
  loginWithFacebook: (response: ReactFacebookLoginInfo) => Promise<void>;
  loginWithGoogle: (sub) => Promise<void>;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const useAuth = () => useContext(AuthContext);

interface LoginResponse {
  auth_token: string;
  is_new_user: boolean;
  refresh_token: string;
}
export const AuthProvider = ({ children, initialUser }) => {
  const [user, setUser] = useState<User | null>(initialUser);

  console.log("user", user);
  const router = useRouter();
  useEffect(() => {
    const fetchGetMe = async () => {
      await getMe();
    };
    if (!user) {
      fetchGetMe();
    }
  }, []);

  const login = async (email: string, password: string) => {
    // Call your API to login and receive the user data and tokens
    const response = await axios.post(
      "/auth/login/email",
      { email, password },
      {
        baseURL: API_BASE_URL,
        headers: {
          Authorization: APP_SECRET_KEY,
        },
      }
    );

    const data = response.data?.data as LoginResponse;
    if (data) {
      setMemberCookie({
        token: data.auth_token,
        refreshToken: data.refresh_token,
      });
      await getMe();
    }
  };

  const register = async (
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ) => {
    // Call your API to register a new user and receive the user data and tokens
    const response = await axios.post(
      "/auth/register/email",
      { email, password, first_name: firstName, last_name: lastName },
      {
        baseURL: API_BASE_URL,
        headers: {
          Authorization: APP_SECRET_KEY,
        },
      }
    );

    const data = response.data?.data as LoginResponse;

    if (data) {
      setMemberCookie({
        token: data.auth_token,
        refreshToken: data.refresh_token,
      });
      await getMe();
    }
  };

  const loginWithFacebook = async (
    facebookResponse: ReactFacebookLoginInfo
  ) => {
    // Extract the user's Facebook access token from the response
    const facebookAccessToken = facebookResponse.accessToken;

    // Call your API to log in with the Facebook access token and receive the user data and tokens
    const response = await axios.post(
      "/auth/login/facebook",
      { access_token: facebookAccessToken },
      {
        baseURL: API_BASE_URL,
        headers: {
          Authorization: APP_SECRET_KEY,
        },
      }
    );

    const data = response.data?.data as LoginResponse;

    if (data) {
      setMemberCookie({
        token: data.auth_token,
        refreshToken: data.refresh_token,
      });
      await getMe();
    }
  };

  const loginWithGoogle = async (sub: string) => {
    // Extract the user's Facebook access token from the response

    // Call your API to log in with the Facebook access token and receive the user data and tokens
    const response = await axios.post(
      "/auth/login/google",
      { access_token: sub },
      {
        baseURL: API_BASE_URL,
        headers: {
          Authorization: APP_SECRET_KEY,
        },
      }
    );

    const data = response.data?.data as LoginResponse;

    if (data) {
      setMemberCookie({
        token: data.auth_token,
        refreshToken: data.refresh_token,
      });
      await getMe();
    }
  };

  const getMe = async () => {
    const cookies = getMemberCookie();
    const token = cookies?.token;
    if (token) {
      const { data } = await axios.get("/v1/me", {
        baseURL: API_BASE_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const userData = data.data as User;
      if (userData) {
        setUser(userData);
        return userData;
      } else {
        throw new Error(data.message);
      }
    }
    return null;
  };

  const refreshToken = async () => {
    // Call your API to refresh the access token using the refresh token

    if (refreshToken) {
      const response = await fetch("/api/refresh-token", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ refreshToken }),
      });

      const data = await response.json();

      if (data.token) {
        setMemberCookie({
          token: data.auth_token,
        });
      } else {
        throw new Error(data.message);
      }
    }
  };

  const verifyEmail = async (token: string) => {
    // Call your API to verify the user's email with the provided token
    const response = await fetch("/api/verify-email", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ token }),
    });

    const data = await response.json();

    if (data.message !== "Email verified") {
      throw new Error(data.message);
    }
  };

  const resendEmailVerify = async () => {
    // Call your API to resend the email verification
    const cookies = getMemberCookie();
    const token = cookies?.token;

    if (token) {
      const response = await fetch("/api/resend-email-verify", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();

      if (data.message !== "Email verification sent") {
        throw new Error(data.message);
      }
    }
  };

  const logout = () => {
    setUser(null);
    resetMemberCookie();
    router?.push("/");
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        isLoggedIn: !!user,
        login,
        register,
        getMe,
        refreshToken,
        verifyEmail,
        resendEmailVerify,
        logout,
        loginWithFacebook,
        loginWithGoogle,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
