import type { AppProps } from "next/app";
import { ThemeProvider, DefaultTheme } from "styled-components";
import GlobalStyle from "../components/globalstyles";
import { SWRConfig } from "swr";
import { API_BASE_URL } from "../config/constants/endpoints";
import axios from "axios";
import Head from "next/head";
import { AuthProvider } from "../contexts/auth-context";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useMemo } from "react";
import PrivacyConsent from "../components/PrivacyConsent/PrivacyConsent";
import { GOOGLE_CLIENT_ID } from "../config/constants";
import { getMemberCookie } from "../utils/cookie";
import { ConfigProvider } from "antd";

export const breakpointMap: { [key: string]: number } = {
  xs: 370,
  sm: 576,
  md: 852,
  lg: 968,
  xl: 1080,
  xxl: 1200,
};

const theme: DefaultTheme = {
  colors: {
    primary: "white",
    secondary: "#0070f3",
  },
  mediaQueries: {
    xs: `@media screen and (min-width: ${breakpointMap.xs}px)`,
    sm: `@media screen and (min-width: ${breakpointMap.sm}px)`,
    md: `@media screen and (min-width: ${breakpointMap.md}px)`,
    lg: `@media screen and (min-width: ${breakpointMap.lg}px)`,
    xl: `@media screen and (min-width: ${breakpointMap.xl}px)`,
    xxl: `@media screen and (min-width: ${breakpointMap.xxl}px)`,
    nav: `@media screen and (min-width: ${breakpointMap.lg}px)`,
  },
};

const title = "PRO HUB THAILAND";
const description =
  "พื้นที่แฮงค์เอาท์ของคนรุ่นใหม่ใจกลางเมือง ดิจิทัลฮับสุดล้ำแห่งแรกในเอเชียตะวันออกเฉียงใต้";
const image = "/images/og-imagae.png";
export default function App({ Component, pageProps }: AppProps) {
  const initialUserMemo = useMemo(
    () => pageProps.initialUser,
    [pageProps.initialUser]
  );
  console.log("fallback", pageProps.fallback);
  const cookies = getMemberCookie();
  const token = cookies?.token;
  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Head>
          <title>{title}</title>
          <meta property="og:title" content={title} />
          <meta property="og:image" content={image} />
          <meta property="og:image:alt" content="og-image" />
          <meta property="og:type" content="website" />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:image" content={image} />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="@GetLinksDPP" />
          <meta name="description" content={description} />
          <meta property="og:description" content={description} />
          <meta name="twitter:description" content={description} />
        </Head>
        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
          <AuthProvider initialUser={initialUserMemo}>
            <SWRConfig
              value={{
                fallback: pageProps.fallback,
                dedupingInterval: 3600000, // hour
                revalidateOnFocus: false,
                fetcher: (url) =>
                  axios({
                    method: "GET",
                    baseURL: API_BASE_URL,
                    url,
                    headers: {
                      Authorization: token && `Bearer ${token}`,
                    },
                  }).then((res) => res.data),
              }}
            >
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: "red",
                    colorLinkActive: "red",
                  },
                }}
              >
                <Component {...pageProps} />
              </ConfigProvider>
              <PrivacyConsent />
            </SWRConfig>
          </AuthProvider>
        </GoogleOAuthProvider>
      </ThemeProvider>
    </>
  );
}
