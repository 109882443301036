import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  html,
  body {
    color: ${({ theme }) => theme.colors.primary};
    padding: 0;
    margin: 0;
    background-color: #031f4a;
    font-family: 'Orbitron', sans-serif;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  * {
    box-sizing: border-box;
  }
  img {
    max-width: 100%;
  }
  video {
    max-width: 100%;
    height: auto;
  }

  iframe {
    max-width: 100%;
    height: auto;
  }
`;

export default GlobalStyle
